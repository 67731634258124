import type { AxiosResponse } from 'axios';
import conf from '@/shared/config/useConfig';

import type { Buerge } from '@/shared/Buergen/types';
import {
  configForDownload,
  configForJsonPost,
  configForJsonPostDownload,
  createApiClient,
  encodeUrl,
  formatDate,
  triggerFakeDownload,
} from './base';

import type { BeziehungNutzerOrganisation, Nutzer, Recht, Rolle } from '../types/Nutzer';
import type {
  BürgeAnzeigedaten,
  Land,
  NutzerAnlagePruefErgebnis,
  Unternehmen,
} from '../types/Partner';
import { buildUrlForServiceName } from './url-retriever';
import type {
  Kautionsvertrag,
  KautionsvertragListItem,
  KautionsvertragPrüfung,
} from '../types/Vertrag';

import type { FilterOptionen } from '../components/datatable/types/FilterObject';
import type { NutzerFilter, NutzerFilterResponse } from './filter-helper/filter-helper-nutzer';
import type {
  UnternehmenFilter,
  UnternehmenFilterResponse,
  UnternehmenSucheFilter,
  UnternehmenSucheResponse,
  UnternehmenSucheRowItem,
} from './filter-helper/filter-helper-unternehmen';
import type { VertragFilter, VertragFilterResponse } from './filter-helper/filter-helper-verträge';
import type { GevoDaten, GevoKanal, Kommunikation } from '../types/Kommunikation';
import { Gevo } from '../types/Kommunikation';
import type {
  TabellenKonfigurationsKeys,
  TabellenKonfiguration,
} from '../common/NutzerKonfiguration/types';

const PARTNERSERVICE_CLIENT = createApiClient(buildUrlForServiceName(conf.partnerserviceName));

export default {
  async createUnternehmen(unternehmen: Unternehmen): Promise<AxiosResponse<Unternehmen>> {
    const data = JSON.stringify(unternehmen);
    return PARTNERSERVICE_CLIENT.post(
      '/partnerservice/api/private/v2/unternehmen/',
      data,
      configForJsonPost,
    );
  },
  async createBürge(bürgeAnzeigedaten: BürgeAnzeigedaten): Promise<AxiosResponse<Buerge>> {
    const data = JSON.stringify(bürgeAnzeigedaten);
    return PARTNERSERVICE_CLIENT.post(
      '/partnerservice/api/private/v2/buerge',
      data,
      configForJsonPost,
    );
  },

  async holeFilterOptionenUnternehmen(
    filtereInaktive?: boolean,
  ): Promise<AxiosResponse<FilterOptionen>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/unternehmen/filteroptionen?filtereInaktive=${
        filtereInaktive || false
      }`,
    );
  },

  async holeUnternehmenMitPaginierungUndFilterungUndSichtberechtigung(
    seite: number,
    seitengröße: number,
    property: string,
    sortierArt: string,
    unternehmenFilter: UnternehmenFilter,
    filtereInaktive?: boolean,
    filtereInkognito?: boolean,
    filtereSichtberechtigung?: boolean,
    auchFremdeUnternehmen?: boolean,
    filtereUngepruefte?: boolean,
  ): Promise<AxiosResponse<UnternehmenFilterResponse>> {
    const data = JSON.stringify(unternehmenFilter);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/unternehmen/filterung?page=${seite}&size=${seitengröße}&sort=${property},${sortierArt}&filtereInaktive=${
        filtereInaktive || false
      }&filtereInkognito=${filtereInkognito || false}&filtereSichtberechtigung=${
        filtereSichtberechtigung || false
      }&auchFremdeUnternehmen=${auchFremdeUnternehmen || false}&filtereUngepruefte=${filtereUngepruefte || false}`,
      data,
      configForJsonPost,
    );
  },

  async holeUnternehmenSucheMitPaginierungUndFilterung(
    seite: number,
    seitengroesse: number,
    property: string,
    sortierArt: string,
    unternehmenSucheFilter: UnternehmenSucheFilter,
  ): Promise<AxiosResponse<UnternehmenSucheResponse>> {
    const data = JSON.stringify(unternehmenSucheFilter);
    return PARTNERSERVICE_CLIENT.post(`/partnerservice/api/private/v2/unternehmen/suche`, data, {
      params: {
        page: seite,
        size: seitengroesse,
        sort: `${property},${sortierArt}`,
      },
      ...configForJsonPost,
    });
  },

  async holeUnternehmenSucheOhnePaginierungUndMitFilterung(
    seite: number,
    seitengroesse: number,
    property: string,
    sortierArt: string,
    unternehmenSucheFilter: UnternehmenSucheFilter,
    filtereInaktive?: boolean,
    filtereInkognito?: boolean,
    filtereSichtberechtigung?: boolean,
    auchFremdeUnternehmen?: boolean,
    filtereUngepruefte?: boolean,
  ): Promise<AxiosResponse<UnternehmenSucheRowItem[]>> {
    const data = JSON.stringify(unternehmenSucheFilter);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/unternehmen/suche/ohnepaging`,
      data,
      {
        params: {
          page: seite,
          size: seitengroesse,
          sort: `${property},${sortierArt}`,
          filtereInaktive,
          filtereInkognito,
          filtereSichtberechtigung,
          auchFremdeUnternehmen,
          filtereUngepruefte,
        },
        ...configForJsonPost,
      },
    );
  },

  async holeTeilnehmerMitPaginierungUndFilterung(
    seite: number,
    seitengröße: number,
    property: string,
    sortierArt: string,
    unternehmenFilter: UnternehmenFilter,
    filtereInaktive?: boolean,
    filtereInkognito?: boolean,
  ): Promise<AxiosResponse<UnternehmenFilterResponse>> {
    const data = JSON.stringify(unternehmenFilter);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/unternehmen/teilnehmer/filterung?page=${seite}&size=${seitengröße}&sort=${property},${sortierArt}&filtereInaktive=${
        filtereInaktive || false
      }&filtereInkognito=${filtereInkognito || false}`,
      data,
      configForJsonPost,
    );
  },

  async holeBuergenMitPaginierungUndFilterung(
    seite: number,
    seitengröße: number,
    property: string,
    sortierArt: string,
    unternehmenFilter: UnternehmenFilter,
    filtereInaktive?: boolean,
    filtereInkognito?: boolean,
    filtereSichtberechtigung?: boolean,
    auchFremdeUnternehmen?: boolean,
  ): Promise<AxiosResponse<UnternehmenFilterResponse>> {
    const data = JSON.stringify(unternehmenFilter);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/unternehmen/buergen/filterung?page=${seite}&size=${seitengröße}&sort=${property},${sortierArt}&filtereInaktive=${
        filtereInaktive || false
      }&filtereInkognito=${filtereInkognito || false}&filtereSichtberechtigung=${
        filtereSichtberechtigung || false
      }&auchFremdeUnternehmen=${auchFremdeUnternehmen || false}`,
      data,
      configForJsonPost,
    );
  },

  /**
   * Ruft ein konkretes Unternehmen ab. Hier wird standardmäßig ein Cache-Header gesetzt, was für die meisten
   * Szenarien sinnvoll ist. Sollte das nicht gewünscht sein, siehe findeUnternehmenNachIdNoCache()
   */
  async findeUnternehmenNachId(id: string): Promise<AxiosResponse<Unternehmen>> {
    return PARTNERSERVICE_CLIENT.get(`/partnerservice/api/private/v2/unternehmen/${id}`);
  },

  /**
   * Ruft ein konkretes Unternehmen ab. Hier wird das Caching explizit umgangen - sollte nur in Ausnahmefällen
   * verwendet werden.
   */
  async findeUnternehmenNachIdNoCache(id: string): Promise<AxiosResponse<Unternehmen>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/unternehmen/${id}?cache=false`,
    );
  },

  async findeBürgeAnzeigedatenNachIdNoCache(id: string): Promise<AxiosResponse<BürgeAnzeigedaten>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/buergen/${id}/anzeigedaten?cache=false`,
    );
  },

  async updateBürgeAnzeigedaten(
    bürgeId: string,
    bürgeAnzeigedaten: BürgeAnzeigedaten,
  ): Promise<AxiosResponse<BürgeAnzeigedaten>> {
    const data = JSON.stringify(bürgeAnzeigedaten);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/buergen/${bürgeId}/anzeigedaten/bearbeitung`,
      data,
      configForJsonPost,
    );
  },

  async findeAuftraggeberNachIdNoCache(id: string): Promise<AxiosResponse<Unternehmen>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/unternehmen/auftraggeber/${id}?cache=false`,
    );
  },

  async findeAlleUnternehmenNamenNachId(id: string[]): Promise<AxiosResponse<Unternehmen[]>> {
    const data = JSON.stringify(id);
    return PARTNERSERVICE_CLIENT.post(
      '/partnerservice/api/private/v2/unternehmen/name',
      data,
      configForJsonPost,
    );
  },

  async erstelleGesellschaft(
    id: string,
    gesellschaft: Unternehmen,
  ): Promise<AxiosResponse<Unternehmen>> {
    const data = JSON.stringify(gesellschaft);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/unternehmen/auftraggeber/${id}/tochter`,
      data,
      configForJsonPost,
    );
  },

  async aktualisiereUnternehmen(unternehmen: Unternehmen): Promise<AxiosResponse<Unternehmen>> {
    const data = JSON.stringify(unternehmen);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/unternehmen/auftraggeber/${unternehmen.unternehmenId}`,
      data,
      configForJsonPost,
    );
  },

  async aktualisiereBürgeLight(bürge: Buerge): Promise<AxiosResponse<Buerge>> {
    const data = JSON.stringify(bürge);
    return PARTNERSERVICE_CLIENT.put(
      `/partnerservice/api/private/v2/unternehmen/buerge/${bürge.unternehmenId}`,
      data,
      configForJsonPost,
    );
  },

  async aktualisiereGesellschaft(
    idUnternehmen: string,
    gesellschaft: Unternehmen,
  ): Promise<AxiosResponse<Unternehmen>> {
    const data = JSON.stringify(gesellschaft);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/unternehmen/auftraggeber/${idUnternehmen}/tochter/${gesellschaft.unternehmenId}`,
      data,
      configForJsonPost,
    );
  },

  /** Gibt an, ob die eigene Organisation (AG) mindestens einen Nutzer mit eingeschränkter Sichtberechtigung */
  async hatUnternehmenSichtbeschränkteNutzer(): Promise<AxiosResponse<boolean>> {
    return PARTNERSERVICE_CLIENT.get(
      'partnerservice/api/private/v2/unternehmen/auftraggeber/sichtbeschraenkung',
    );
  },

  /** Ruft alle Bürgen aus dem System ab. Hier wird ein Cache-Header gesetzt, kann also bedenkenlos öfter aufgerufen werden */
  async findeAlleBürgen(): Promise<AxiosResponse<Buerge[]>> {
    return PARTNERSERVICE_CLIENT.get('/partnerservice/api/private/v2/buergen');
  },

  async holeAlleBenutzer(): Promise<AxiosResponse<Nutzer[]>> {
    return PARTNERSERVICE_CLIENT.get('/partnerservice/api/private/v2/nutzerverwaltung/benutzer');
  },

  async holeFilterOptionenNutzer(): Promise<AxiosResponse<FilterOptionen>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/nutzerverwaltung/nutzer/filteroptionen`,
    );
  },

  async holeFilterOptionenNutzerMitUnternehmenId(
    unternehmenId: string,
  ): Promise<AxiosResponse<FilterOptionen>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/nutzerverwaltung/nutzer/${unternehmenId}/filteroptionen/`,
    );
  },

  async holeNutzerMitPaginierungUndFilterung(
    seite: number,
    seitengröße: number,
    property: string,
    sortierArt: string,
    nutzerFilter: NutzerFilter,
  ): Promise<AxiosResponse<NutzerFilterResponse>> {
    const data = JSON.stringify(nutzerFilter);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/nutzerverwaltung/nutzer/filterung?page=${seite}&size=${seitengröße}&sort=${property},${sortierArt}`,
      data,
      configForJsonPost,
    );
  },

  async holeNutzerAnhandEmail(email: string): Promise<AxiosResponse<Nutzer>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/nutzerverwaltung/benutzer/email/${email}`,
    );
  },

  async pruefeBeziehungNutzerOrganisation(
    email: string,
  ): Promise<AxiosResponse<BeziehungNutzerOrganisation>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/nutzerverwaltung/benutzer/beziehungeigeneorganisation/${email}`,
    );
  },

  async holeAdminNutzerFürFremdeOrganisationMitFilterungUndPaginierung(
    seite: number,
    seitengröße: number,
    property: string,
    sortierArt: string,
    nutzerFilter: NutzerFilter,
    unternehmenId: string,
  ): Promise<AxiosResponse<NutzerFilterResponse>> {
    const data = JSON.stringify(nutzerFilter);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/nutzerverwaltung/nutzer/${unternehmenId}/filterung?page=${seite}&size=${seitengröße}&sort=${property},${sortierArt}`,
      data,
      configForJsonPost,
    );
  },

  async holeAdminNutzerFürFremdeOrganisation(
    unternehmenId: string,
  ): Promise<AxiosResponse<Nutzer[]>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/nutzerverwaltung/${unternehmenId}/nutzer`,
    );
  },

  async holeAlleRollen(): Promise<AxiosResponse<Rolle[]>> {
    return PARTNERSERVICE_CLIENT.get('/partnerservice/api/private/v2/nutzerverwaltung/rollen/alle');
  },

  async holeAlleRollenNichtAdmin(): Promise<AxiosResponse<Rolle[]>> {
    return PARTNERSERVICE_CLIENT.get(
      '/partnerservice/api/private/v2/nutzerverwaltung/rollen/alle?admin=false',
    );
  },

  async holeEinzelneRolle(rolleName: string): Promise<AxiosResponse<Rolle>> {
    return PARTNERSERVICE_CLIENT.get(`/partnerservice/api/private/v2/nutzerverwaltung/rollen`, {
      params: {
        name: rolleName,
      },
    });
  },

  async erstelleRolle(rolle: Rolle): Promise<AxiosResponse<void>> {
    const data = JSON.stringify(rolle);
    return PARTNERSERVICE_CLIENT.post(
      '/partnerservice/api/private/v2/nutzerverwaltung/rollen/erstellen',
      data,
      configForJsonPost,
    );
  },

  async aktualisiereRolle(rolle: Rolle, rolleName: string): Promise<AxiosResponse<void>> {
    const data = JSON.stringify(rolle);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/nutzerverwaltung/rollen/aktualisieren`,
      data,
      {
        params: {
          name: rolleName,
        },
        ...configForJsonPost,
      },
    );
  },

  async löscheRolle(rolleName: string): Promise<AxiosResponse<void>> {
    return PARTNERSERVICE_CLIENT.delete(`/partnerservice/api/private/v2/nutzerverwaltung/rollen`, {
      params: {
        name: rolleName,
      },
      ...configForJsonPost,
    });
  },

  async sindGevoKanalAuspraegungenVorhanden(
    erforderlichesRecht: boolean,
    gevoKonfiguration: Gevo,
    gevoKanalAuspraegungen: GevoKanal[],
    buergeId: string,
  ): Promise<boolean> {
    const response = await this.holeBürgenKommunikation(gevoKonfiguration, buergeId);
    const gevoKanalArr = [response.data.gevoKanalDto];
    return erforderlichesRecht && gevoKanalAuspraegungen.some((gk) => gevoKanalArr.includes(gk));
  },

  async holeBürgenKommunikation(
    gevo: Gevo,
    bürgeId: string,
  ): Promise<AxiosResponse<Kommunikation>> {
    const gevoString: string = Gevo[gevo];
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/buergen/${bürgeId}/gevo/${gevoString}`,
      configForJsonPost,
    );
  },

  async holeBürgenKommunikationMitGevo(
    gevo: Gevo,
    bürgeId: string,
  ): Promise<AxiosResponse<Kommunikation>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/buergen/${bürgeId}/gevo/${gevo}?cache=false`,
      configForJsonPost,
    );
  },

  async holeBürgen(): Promise<AxiosResponse<Buerge[]>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/buergen?cache=false`,
      configForJsonPost,
    );
  },

  async holeGevoDatenBürge(
    bürgeId: string,
    property: string,
    sortierArt: string,
  ): Promise<AxiosResponse<GevoDaten>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/buergen/${bürgeId}/gevos?sort=${property},${sortierArt}`,
      configForJsonPost,
    );
  },

  async aktualisiereGevoDefaultEmail(bürgeId: string, mail: string): Promise<AxiosResponse<void>> {
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/buergen/${bürgeId}/gevo/default-email/bearbeitung`,
      mail,
      configForJsonPost,
    );
  },

  async aktualisiereGevo(bürgeId: string, gevo: Kommunikation): Promise<AxiosResponse<void>> {
    const data = JSON.stringify(gevo);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/buergen/${bürgeId}/gevo/bearbeitung`,
      data,
      configForJsonPost,
    );
  },

  async holeAuftraggeberKommunikation(
    gevo: Gevo,
    unternehmenId: string,
  ): Promise<AxiosResponse<Kommunikation>> {
    const gevoString: string = Gevo[gevo];
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/unternehmen/auftraggeber/${unternehmenId}/gevo/${gevoString}`,
      configForJsonPost,
    );
  },

  async holeAuftraggeberKommunikationMitGevo(
    gevo: Gevo,
    unternehmenId: string,
  ): Promise<AxiosResponse<Kommunikation>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/unternehmen/auftraggeber/${unternehmenId}/gevo/${gevo}?cache=false`,
      configForJsonPost,
    );
  },

  async holeGevoDatenAuftraggeber(
    unternehmenId: string,
    property: string,
    sortierArt: string,
  ): Promise<AxiosResponse<GevoDaten>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/unternehmen/auftraggeber/${unternehmenId}/gevos?sort=${property},${sortierArt}`,
      configForJsonPost,
    );
  },

  async aktualisiereGevoAuftraggeber(
    unternehmenId: string,
    gevo: Kommunikation,
  ): Promise<AxiosResponse<void>> {
    const data = JSON.stringify(gevo);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/unternehmen/auftraggeber/${unternehmenId}/gevo/bearbeitung`,
      data,
      configForJsonPost,
    );
  },

  async holeAlleRechte(): Promise<AxiosResponse<Recht[]>> {
    return PARTNERSERVICE_CLIENT.get('/partnerservice/api/private/v2/nutzerverwaltung/rechte');
  },

  async holeEigeneRechte(): Promise<AxiosResponse<Recht[]>> {
    return PARTNERSERVICE_CLIENT.get(
      '/partnerservice/api/private/v2/nutzerverwaltung/rechte/nutzer',
    );
  },

  async holeEinzelnenBenutzer(id: string): Promise<AxiosResponse<Nutzer>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/nutzerverwaltung/benutzer/${id}`,
    );
  },

  async holeEigenenBenutzer(): Promise<AxiosResponse<Nutzer>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/nutzerverwaltung/benutzer/eigenen`,
    );
  },

  async erstelleBenutzer(nutzer: Nutzer): Promise<AxiosResponse<void>> {
    const data = JSON.stringify(nutzer);
    return PARTNERSERVICE_CLIENT.post(
      '/partnerservice/api/private/v2/nutzerverwaltung/benutzer',
      data,
      configForJsonPost,
    );
  },

  async benutzerWiederherstellen(nutzer: Nutzer): Promise<AxiosResponse<void>> {
    const data = JSON.stringify(nutzer);
    return PARTNERSERVICE_CLIENT.post(
      '/partnerservice/api/private/v2/nutzerverwaltung/benutzer/wiederherstellen',
      data,
      configForJsonPost,
    );
  },

  async erstelleAdminBenutzer(nutzer: Nutzer, unternehmenId: string): Promise<AxiosResponse<void>> {
    const data = JSON.stringify(nutzer);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/nutzerverwaltung/${unternehmenId}/nutzer`,
      data,
      configForJsonPost,
    );
  },

  async updateNutzer(nutzer: Nutzer): Promise<AxiosResponse<void>> {
    const data = JSON.stringify(nutzer);
    return PARTNERSERVICE_CLIENT.put(
      '/partnerservice/api/private/v2/nutzerverwaltung/benutzer',
      data,
      configForJsonPost,
    );
  },

  async updateAdminNutzer(nutzer: Nutzer): Promise<AxiosResponse<void>> {
    const data = JSON.stringify(nutzer);
    return PARTNERSERVICE_CLIENT.put(
      '/partnerservice/api/private/v2/nutzerverwaltung/nutzer',
      data,
      configForJsonPost,
    );
  },

  async löscheNutzer(nutzerId: string): Promise<AxiosResponse<Nutzer>> {
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/nutzerverwaltung/benutzer/${nutzerId}/loeschung`,
    );
  },

  async deaktiviereNutzer(nutzerId: string, token: unknown): Promise<AxiosResponse<void>> {
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/nutzerverwaltung/benutzer/${nutzerId}/deaktivierung`,
      token,
      configForJsonPost,
    );
  },

  async deaktiviereEigenenNutzer(token: unknown): Promise<AxiosResponse<void>> {
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/nutzerverwaltung/benutzer/deaktivierung`,
      token,
      configForJsonPost,
    );
  },

  async einstellungErhaltNutzerEMails(erhaltNutzerEMails: unknown): Promise<AxiosResponse<void>> {
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/nutzerverwaltung/email/einstellung`,
      erhaltNutzerEMails,
      configForJsonPost,
    );
  },

  async tochterunternehmenFreigeben(unternehmen: Unternehmen): Promise<AxiosResponse<Unternehmen>> {
    const data = JSON.stringify(unternehmen);
    return PARTNERSERVICE_CLIENT.post(
      '/partnerservice/api/private/v2/unternehmen/auftraggeber/freigeben',
      data,
      configForJsonPost,
    );
  },

  async findeLänderliste(): Promise<AxiosResponse<Land[]>> {
    return PARTNERSERVICE_CLIENT.get('/partnerservice/api/private/v2/laenderliste/');
  },

  async holeKautionsverträge(
    vertragsnummer: string,
    buergeId: string,
  ): Promise<AxiosResponse<Kautionsvertrag[]>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/kautionsvertraege/buerge/${buergeId}`,
      {
        ...configForJsonPost,
        params: {
          vertragsnummer,
        },
      },
    );
  },

  async holeFilterOptionenVerträge(unternehmenId?: string): Promise<AxiosResponse<FilterOptionen>> {
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/kautionsvertraege/filteroptionen`,
      undefined,
      {
        params: { unternehmenId },
      },
    );
  },

  async holeVerträgeMitPaginierungUndFilterung(
    seite: number,
    seitengröße: number,
    property: string,
    sortierArt: string,
    vertragFilter: VertragFilter,
  ): Promise<AxiosResponse<VertragFilterResponse>> {
    const data = JSON.stringify(vertragFilter);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/kautionsvertraege/filterung?page=${seite}&size=${seitengröße}&sort=${property},${sortierArt}`,
      data,
      configForJsonPost,
    );
  },

  async holeKautionsvertragById(
    kautionsvertragId: number,
  ): Promise<AxiosResponse<Kautionsvertrag>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/kautionsvertraege/${kautionsvertragId}`,
      configForJsonPost,
    );
  },

  async holeKautionsvertragByPrüfungId(
    kautionsvertragsPrüfungId: string,
  ): Promise<AxiosResponse<Kautionsvertrag>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/kautionsvertraege/pruefung/${kautionsvertragsPrüfungId}`,
      configForJsonPost,
    );
  },

  async holeAlleKautionsverträge(): Promise<AxiosResponse<KautionsvertragListItem[]>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/kautionsvertraege`,
      configForJsonPost,
    );
  },

  async erstelleKautionsvertrag(
    vertragsnummer: string,
    bürgeId: string,
    versicherungsnehmerId: string,
    weitereUnternehmenIds: string[],
  ): Promise<AxiosResponse<Kautionsvertrag>> {
    const data = JSON.stringify(weitereUnternehmenIds);
    return PARTNERSERVICE_CLIENT.post(
      encodeUrl(
        `/partnerservice/api/private/v2/kautionsvertraege?vertragsnummer={0}&bürgeId={1}&versicherungsnehmerId={2}`,
        [vertragsnummer, bürgeId, versicherungsnehmerId],
      ),
      data,
      configForJsonPost,
    );
  },

  async bearbeiteKautionsvertrag(
    kautionsvertragId: string,
    versicherungsnehmerId: string,
    weitereUnternehmenIds: string[],
  ): Promise<AxiosResponse<Kautionsvertrag>> {
    const data = JSON.stringify(weitereUnternehmenIds);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/kautionsvertraege/${kautionsvertragId}?versicherungsnehmerId=${versicherungsnehmerId}`,
      data,
      configForJsonPost,
    );
  },

  async fügeUnternehmenZuKautionsvertragHinzu(
    kautionsvertragId: string,
    weitereUnternehmenIds: string[],
  ): Promise<AxiosResponse<Kautionsvertrag>> {
    const data = JSON.stringify(weitereUnternehmenIds);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/kautionsvertraege/${kautionsvertragId}`,
      data,
      configForJsonPost,
    );
  },

  async schließeVertragsPrüfungAbByPrüfungsId(
    kautionsvertragsPrüfungsId: string,
    prüfung: KautionsvertragPrüfung,
  ): Promise<AxiosResponse<Kautionsvertrag>> {
    const data = JSON.stringify(prüfung);
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/kautionsvertraege/pruefung/${kautionsvertragsPrüfungsId}/abschluss`,
      data,
      configForJsonPost,
    );
  },

  async downloadVertragDokumentation(
    kautionsvertragId: number,
    versicherungsnehmerId: string,
  ): Promise<void> {
    const response = await PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/kautionsvertraege/${kautionsvertragId}/dokumentation`,
      configForDownload,
    );

    const formattedDate = formatDate(new Date(), 'ddmmyyyy');
    const fileName = `Vertragsverknüpfung_${versicherungsnehmerId}_${formattedDate}.pdf`;
    triggerFakeDownload(response.data, fileName);
  },

  async downloadAlleUnternehmenCsv(unternehmenFilter: UnternehmenFilter): Promise<void> {
    /**
     * TL:     Die Aufruferrolle wird in der Domäne TL mitgegeben, um zu unterscheiden, ob die
     *         Unternehmen exportiert werden sollen
     */
    const data = JSON.stringify(unternehmenFilter);
    const url = '/partnerservice/api/private/v2/unternehmen/filterung/unternehmen.csv';
    const response = await PARTNERSERVICE_CLIENT.post(url, data, configForJsonPostDownload);
    const formattedDate = formatDate(new Date(), 'yyyymmdd');
    const fileName = `${formattedDate}_Unternehmen.csv`;
    triggerFakeDownload(response.data, fileName);
  },

  async downloadTeilnehmerCsv(unternehmenFilter: UnternehmenFilter): Promise<void> {
    const data = JSON.stringify(unternehmenFilter);
    const url = '/partnerservice/api/private/v2/unternehmen/teilnehmer/filterung/unternehmen.csv';
    const response = await PARTNERSERVICE_CLIENT.post(url, data, configForJsonPostDownload);
    const formattedDate = formatDate(new Date(), 'yyyymmdd');
    const fileName = `${formattedDate}_Unternehmen.csv`;
    triggerFakeDownload(response.data, fileName);
  },

  async ermittelGevoDatenFürBürgen(): Promise<AxiosResponse<GevoDaten>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/buergen/gevos`,
      configForJsonPost,
    );
  },

  async findeBürge(): Promise<AxiosResponse<Buerge>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/buergen/buerge`,
      configForJsonPost,
    );
  },

  async istBürgeMitFremdKurznameBereitsVorhanden(
    kurzname: string,
  ): Promise<AxiosResponse<boolean>> {
    return PARTNERSERVICE_CLIENT.get(
      `/partnerservice/api/private/v2/buergen/istVorhanden?kurzname=${kurzname}`,
    );
  },

  async pruefeObNutzerAngelegtWerdenKoennte(email: string): Promise<NutzerAnlagePruefErgebnis> {
    if (!email) {
      return undefined;
    }
    const response = await this.pruefeBeziehungNutzerOrganisation(email);
    const beziehungNutzerOrganisation = response.data;

    let koennteAngelegtWerden = false;
    let nutzerExistiertInEigenerOrganisation = false;
    let nutzerKannWiederhergestelltWerden = false;
    switch (beziehungNutzerOrganisation.status) {
      case '100':
        nutzerExistiertInEigenerOrganisation = false;
        nutzerKannWiederhergestelltWerden = false;
        koennteAngelegtWerden = true;
        break;
      case '200':
        nutzerExistiertInEigenerOrganisation = false;
        nutzerKannWiederhergestelltWerden = false;
        break;
      case '300':
        nutzerExistiertInEigenerOrganisation = true;
        nutzerKannWiederhergestelltWerden = false;
        break;
      case '400':
        nutzerKannWiederhergestelltWerden = true;
        nutzerExistiertInEigenerOrganisation = false;
        break;
      default:
        break;
    }
    return {
      koennteAngelegtWerden,
      nutzerExistiertInEigenerOrganisation,
      nutzerKannWiederhergestelltWerden,
    };
  },

  // Wenn nichts gefunden wird zu dem Key wird eine leere Response zurückgegeben,
  // daraus macht Axios einen leeren String, daher der union return type.
  async holeNutzerTabellenKonfiguration(
    key: TabellenKonfigurationsKeys,
  ): Promise<TabellenKonfiguration | string> {
    const result = await PARTNERSERVICE_CLIENT.get<TabellenKonfiguration>(
      `/partnerservice/api/private/v2/nutzerverwaltung/${key}/einstellung`,
    );

    return result.data;
  },

  async speicherNutzerTabellenKonfiguration(
    key: TabellenKonfigurationsKeys,
    konfiguration: TabellenKonfiguration,
  ): Promise<void> {
    return PARTNERSERVICE_CLIENT.post(
      `/partnerservice/api/private/v2/nutzerverwaltung/${key}/einstellung`,
      konfiguration,
    );

    // todo error handling klären?
  },
};
