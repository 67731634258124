<template>
  <button :disabled="disabled" type="button" :class="classes" v-bind="$attrs">
    <TIcon v-if="props.icon" :icon="icon" :size="TIconSize.X_SMALL" />
    <slot>{{ text }} </slot>
  </button>
</template>

<script setup lang="ts">
import type { Icon } from '@/design-system/IconLib/types';
import { computed } from 'vue';
import TIcon from '@/design-system/atoms/TIcon/TIcon.vue';
import { TIconSize } from '@/design-system/atoms/TIcon/types';
import type {
  TButtonIconPosition,
  TButtonSupport,
  TButtonWidth,
} from '@/design-system/atoms/TButton/types';
import { TButtonSize, TButtonType } from '@/design-system/atoms/TButton/types';

interface Props {
  text?: string;
  disabled?: boolean;
  icon?: Icon;
  iconPosition?: TButtonIconPosition;
  type?: TButtonType;
  destructive?: boolean;
  width?: TButtonWidth;
  size?: TButtonSize;
  support?: TButtonSupport;
}

defineOptions({
  name: 'TButton',
});

const props = withDefaults(defineProps<Props>(), {
  text: '',
  disabled: null,
  icon: null,
  iconPosition: null,
  type: null,
  destructive: false,
  width: null,
  size: TButtonSize.BIG,
  support: null,
});

const classes = computed(() => ({
  't-button': true,
  [`t-button--${props.type}`]: props.type && !props.destructive,
  [`t-button--${props.type}--destructive`]: props.type && props.destructive,
  [`t-button--${props.width}--width`]: props.width,
  [`t-button--${props.size}`]: props.size,
  [`t-button--${props.support}`]: props.support,
  [`t-button--tertiary--support`]: props.support && props.type === TButtonType.TERTIARY,
  [`t-button--icon--${props.iconPosition}`]: props.iconPosition,
}));
</script>
