export enum BuergeRoutes {
  // Aufgaben
  BUERGE_AUFGABEN_LISTE = 'aufgabenListeBürge',

  // Antrag
  BUERGE_ANTRAG_DETAIL = 'buergschaftsauftragDetailsBuerge',
  // Antrag prüfen
  BUERGE_ANTRAG_PRUEFEN_AUFGABE_BASE = 'buerge-antrag-pruefen-aufgabe-base',
  BUERGE_ANTRAG_PRUEFEN_AUFGABE_DETAILS = 'buerge-antrag-pruefen-aufgabe-details',
  BUERGE_ANTRAG_PRUEFEN_AUFGABE_ENTSCHEIDUNG = 'buerge-antrag-pruefen-aufgabe-entscheidung',
  BUERGE_ANTRAG_PRUEFEN_AUFGABE_AKZEPTIEREN = 'buerge-antrag-pruefen-aufgabe-akzeptieren',
  BUERGE_ANTRAG_PRUEFEN_AUFGABE_ABLEHNEN = 'buerge-antrag-pruefen-aufgabe-ablehen',
  // Bürgschaften
  BUERGE_BUERGSCHAFT_LIST = 'bürgschaftenListeBürge',

  BUERGE_BUERGSCHAFT_DETAIL = 'bürgschaftDetailsBürge',
  BUERGE_BUERGSCHAFT_ANLEGEN = 'buerge-buergschaft-anlegen-base',
  BUERGE_BUERGSCHAFT_ANLEGEN_AUFTRAGGEBER = 'buerge-buergschaft-anlegen-auftraggeber',
  BUERGE_BUERGSCHAFT_ANLEGEN_AUFTRAGNEHMER = 'buerge-buergschaft-anlegen-auftragnehmer',
  BUERGE_BUERGSCHAFT_ANLEGEN_BUERGSCHAFTSDATEN = 'buerge-buergschaft-anlegen-buergschaftsdaten',
  BUERGE_BUERGSCHAFT_ANLEGEN_BUERGSCHAFTSDOKUMENT = 'buerge-buergschaft-anlegen-buergschaftsdokument',
  BUERGE_BUERGSCHAFT_ANLEGEN_ZUSAMMENFASSUNG = 'buerge-buergschaft-anlegen-zusammenfassung',

  BUERGE_BUERGSCHAFT_ERSTELLEN = 'buerge-buergschaft-erstellen-base',
  BUERGE_BUERGSCHAFT_ERSTELLEN_DATEN = 'buerge-buergschaft-erstellen-daten',
  BUERGE_BUERGSCHAFT_ERSTELLEN_TEXT = 'buerge-buergschaft-erstellen-text',
  BUERGE_BUERGSCHAFT_ERSTELLEN_PRUEFEN = 'buerge-buergschaft-erstellen-pruefen',
}
